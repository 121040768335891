.container {
  @apply bg-white p-6 pt-16 h-full;
}

.infoDisplay {
  @apply mt-5 rounded-3xl overflow-hidden h-64 lg-mobile:h-vh2,5 shadow-md;
}

.appBar {
  @apply h-14 p-1 justify-between;
}

.logo {
  @apply h-10;
}

.mapContainer {
  height: calc(100vh - 68px);
}

.screen {
  @apply h-screen bg-customGrey;
}
